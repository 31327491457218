const trueInformationsCheckBox = document.querySelector("[data-enable-submit]")
if (trueInformationsCheckBox) {
  trueInformationsCheckBox.addEventListener("click", () => {
    const targetSumbitBtn = document.querySelector(trueInformationsCheckBox.dataset.enableSubmit)
    if(trueInformationsCheckBox.checked) {
      targetSumbitBtn.classList.remove("btn-secondary")
      targetSumbitBtn.classList.add("btn-primary")
      targetSumbitBtn.disabled = false
    } else {
      targetSumbitBtn.classList.add("btn-secondary")
      targetSumbitBtn.classList.remove("btn-primary")
      targetSumbitBtn.disabled = true
    }
  })
}
