const subscriptionsMobileNav = document.querySelector('.steps-static-mobile-nav');
if (subscriptionsMobileNav) {
  window.addEventListener('scroll', () => {
    if (window.scrollY > subscriptionsMobileNav.offsetTop) {
      subscriptionsMobileNav.classList.add('fixed');
      subscriptionsMobileNav.classList.add('pt-5');
    } else {
      subscriptionsMobileNav.classList.remove('fixed');
      subscriptionsMobileNav.classList.remove('pt-5');
    }
  });
}