import axios from "axios";
import I18n from "../../../i18n/setup.js.erb";

const csrfToken = document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").content : null;

const bankAccountValidation = () => {
  const bankAccountContinueButtons = document.querySelectorAll(".bank-account-continue")
  if (bankAccountContinueButtons) {
    bankAccountContinueButtons.forEach(element => {
      element.addEventListener("click", () => {
        handleValidationFor(element)
      })
    })
  }
}

const handleValidationFor = (element) => {
  const id = element.id.split("-")[3]
  const bankAccountForm = document.querySelector(`#edit_user_bank_account_${id}`)
  const iban = bankAccountForm.querySelector("#user_bank_account_iban").value
  const bic = bankAccountForm.querySelector("#user_bank_account_bic").value
  axios.post(`/app/validate_bic_and_iban`, {iban: iban, bic: bic}, {
    headers: {
      'X-CSRF-Token': csrfToken
    }
  }).then(response => {
    let validationResult = response.data
    if (validationResult.success) {
      handleValidationFeedback(bankAccountForm, validationResult)
      handleSuccessfulValidationFor(bankAccountForm)
    } else {
      handleValidationFeedback(bankAccountForm, validationResult)
    }
  })
  .catch(error => {
    handleValidationFeedback(element, {error: error, iban_valid: false, bic_valid: false})
  })
}

const handleValidationFeedback = (form, validationResult) => {
  const bicInput = form.querySelector("#user_bank_account_bic")
  const ibanInput = form.querySelector("#user_bank_account_iban")
  if (validationResult.bic_valid) {
      bicInput.classList.remove("is-invalid")
      bicInput.classList.add("is-valid")
      bicInput.nextElementSibling.innerHTML = ""
  } else {
    bicInput.classList.add("is-invalid")
    bicInput.nextElementSibling.innerHTML = `<div class='invalid-feedback d-block'> ${I18n.t("investor_app.bank_accounts.validation.bic_invalid")}</div>`
  }
  if (validationResult.iban_valid) {
    ibanInput.classList.remove("is-invalid")
    ibanInput.classList.add("is-valid")
    ibanInput.nextElementSibling.innerHTML = ""
  } else {
    ibanInput.classList.add("is-invalid")
    ibanInput.nextElementSibling.innerHTML = `<div class='invalid-feedback d-block'> ${I18n.t("investor_app.bank_accounts.validation.iban_invalid")}</div>`
  }
}

const handleSuccessfulValidationFor = (form) => {
  const elementsToToggle =  form.querySelectorAll(".AppSettings--bank-form")
  elementsToToggle.forEach(e => e.classList.toggle('d-none'))
}

document.addEventListener("DOMContentLoaded", () => {
  bankAccountValidation();
})