$(function() {
  var form = $('.projects-header form');

  if (form.length > 0) {
    form.find('select#district').change(function(){
      form.submit();
    });

    form.submit(function(event) {
      $('.main-content .projects').html('\
        <div class="px-2 px-sm-4 pt-4 mb-4">\
          <div class="enerfip-loader"></div>\
        </div>\
      ');
      // event.preventDefault();
    });
  }
});
