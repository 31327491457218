// https://bootsnipp.com/snippets/VgkV
$(function() {
  $('.cgu_acceptance_panel .button-checkbox').each(function () {
    var button = $(this).find('button');
    var checkbox = $(this).find('input:checkbox');

    function updateDisplay() {
      if (checkbox.is(':checked')) {
        button.replaceWith('<i class="fas fa-check"></i>')
      }
    }

    button.click(function() {
      checkbox.prop('checked', true);
      updateDisplay();
    });

    updateDisplay();
  })
});
