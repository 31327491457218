import { projectSectionLinkOffset } from './sectionLinkOffset'

document.addEventListener("DOMContentLoaded", () => {
  const spyNavWrapper = document.querySelector('.spy-nav-wrapper')
  if (spyNavWrapper) {
    const sectionOffset = spyNavWrapper.dataset.offset || 0
    const section = document.querySelectorAll(".spy-section");
    let sections = {};
    let i = 0;

    Array.prototype.forEach.call(section, function(e) {
      sections[e.id] = e.offsetTop - sectionOffset;
    });

    window.onscroll = function() {
      let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

      for (i in sections) {
        if (sections[i] <= scrollPosition) {
          let current_active = document.querySelector('.spy-nav-wrapper .active')
          if (current_active) {current_active.classList.remove('active')};
          document.querySelector('.spy-nav-wrapper a[href*=' + i + ']').classList.add('active');
        }
      }
    };

    projectSectionLinkOffset(sectionOffset)
  }
})