$(function() {
  $('button[type=submit][data-loading-text].btn').on('click', function(e) {
    var $this = $(this);
    var form = $this.closest('form');
    if (form[0].checkValidity()) {
      $this.html($this.data('loading-text')).prop('disabled', true);
      form.submit();
    }
  });
})
