$().ready(function() {
  $('.read_more').click(function(e) {
    e.preventDefault();
    var target = $(this);
    var nextText = target.parent('p').find('.hide_read_more');
    var elipsis = target.parent('p').find('.read_more_elipsis');
    nextText.toggle();
    elipsis.toggle();
    target.toggleClass("is-open");

    if (target.hasClass("is-open")) {
      target.data('original-html', target.html());
      target.text(" Fermer");
    } else {
      // target.text(" Lire la suite...");
      target.html(target.data('original-html'));
    }
  });
});
