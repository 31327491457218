$(function() {
  var form = $('.projects-sidebar form');
  var container = $("#francemap-search");

  if (form.length > 0) {
    if (container.length > 0) {
      container.click(function(){
        form.submit()
      });
    };

    form.find('.filters input').change(function(){
      form.submit();
    });

    form.submit(function(event) {
      $('.main-content .projects').html('\
        <div class="px-2 px-sm-4 pt-4 mb-4">\
          <div class="enerfip-loader"></div>\
        </div>\
      ');
      // event.preventDefault();
    });
  }

  if (form.length > 0) {
    form.find('.text-end button[type=submit]').hide();
  }
});
