document.addEventListener('DOMContentLoaded', function () {
  const copySponsorInviteToClipboard = document.querySelector('#sponsorship-invite-to-copy-button');
  const sponsorInviteText = document.querySelector('#sponsorship-invite-to-copy-text')?.innerHTML;

  function clipboardCopyTo(value) {
    var tempInput = document.createElement("textarea");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  if (copySponsorInviteToClipboard && sponsorInviteText) {
    copySponsorInviteToClipboard.addEventListener('click', function () {
      clipboardCopyTo(sponsorInviteText);
    });
  };
});
