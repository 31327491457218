$(document).ready(function() {
  var container = $("#francemap-search");

  if(container.length > 0) {
    var url = container.data("url");
    var input = container.data("input");
    var selected_regions = container.data("selected-regions");
    var fallback_input = $('#selected-regions-fallback');
    var update_filters = function(element, code, region) {
      if(map.selectedRegions.length === 0) {
        $(input).val("")
      } else {
        $(input).val(map.selectedRegions)
      }
      // sync fallback input
      if(fallback_input.length > 0) {
        fallback_input.val($(input).val().split(","));
      }
    }

    var map;

    var getAppValue = function(name) {
      return getComputedStyle(document.documentElement).getPropertyValue('--app-'+name);
    }

    var hoverColor = getAppValue('color-brand-border');
    var color = getAppValue('color-brand-light');
    var borderColor = getAppValue('color-brand-dark');
    var selectedColor = getAppValue('color-brand-links');
    // var activeColor = getAppValue('color-white-a20');
    var activeColor = getAppValue('color-brand-links');
    var inactiveColor = color; // "#2C64A3"

  var displayMap = function(colors) {
    container.html("");

    map = container.vectorMap({
      map: 'france_fr',
      hoverColor: hoverColor,
      backgroundColor: "transparent",
      colorOpacity: 0.8,
      color: color,
      colors: colors, // if provided
      borderColor: borderColor,
      selectedColor: selectedColor,
      multiSelectRegion: true, // or false ?
      enableZoom: false,
      showTooltip: true,
      selectedRegions: selected_regions,
      onRegionSelect: update_filters,
      onRegionDeselect: update_filters
    });

    if (map.selectedRegions) {
      update_filters();
    }
  }

    // Amend district labels with number of results
    /*
    var updateLabels = function(data) {
      var mapData = JQVMap.maps["france_fr"];
      for (var key in data) {
        var path = mapData.paths[key]
        if (path && data[key] > 0) {
          path.name = path.name + " (" + data[key] + ")";
        }
      }
    }
    */

    if (url) {
      $.getJSON(url, function(data) {
        var colors = Object.keys(data).reduce(function(previous, current) {
          previous[current] = data[current] > 0 ? activeColor : inactiveColor;
          return previous;
        }, {});

        // updateLabels(data);
        displayMap(colors);
      });
    } else {
      displayMap();
    }

  }
});
