import { Tab } from "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
   var hash = document.location.hash;
   var prefix="go";
   const defaultTab = document.querySelector("[data-bs-target='#the-project']")
   if (hash) {
      var triggerEls = document.querySelectorAll(`[data-bs-target='${hash.replace(prefix,"")}']`)
      if (triggerEls.length > 0) {
        new Tab(defaultTab).show()
        setTimeout(() => {
          triggerEls.forEach(triggerEl => {
            var tabTrigger = new Tab(triggerEl)
            tabTrigger.show()
          })
          document.querySelector("#project-tab-navigation").scrollIntoView({behavior: "smooth", block: "start"})
        }, 100)
      }
    } else {
      if (defaultTab) {
        new Tab(defaultTab).show()
      }
    }
   document.querySelectorAll(".arkefip-project-page-tab-navigation a").forEach(link => {
     link.addEventListener("shown.bs.tab", (e) => {
      document.querySelectorAll(`[data-bs-target='${e.target.dataset.bsTarget}']`).forEach(tab => {
        new Tab(tab).show()
      })
      document.querySelector("#project-tab-navigation").scrollIntoView({behavior: "smooth", block: "start"})
      window.history.pushState({}, "", e.target.hash.replace("#","#" + prefix));
    })
   })
});
