$().ready(
  function()
  {
    // Most of app/assets/javascripts/app.js has been moved out.
    //
    // [...]                                        (auto_open_modal, ...)
    // app/assets/javascripts/lib/ujs.js            (data-remote, data-click-url)
    // [...]                                        (.not_loaded)
    // node_modules/read_more.js  (.read_more)
  }
);
