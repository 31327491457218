import "jquery.ui.widget/jquery.ui.widget";
import "blueimp-file-upload/js/jquery.fileupload";
import "blueimp-file-upload/js/jquery.fileupload-process";
import "blueimp-file-upload/js/jquery.fileupload-validate";

$(function() {
  var uploadInProgressCount = 0;
  $.widget('blueimp.fileupload', $.blueimp.fileupload, {
    options: {
      messages: {
        acceptFileTypes: "Le fichier n'est pas dans un format autorisé",
        maxFileSize: "Le fichier excède la taille autorisée"
      }
    }
  });
  $("input.directUpload").attr("type", "").attr("type", "file");
  $('body').on('click drop', '.directUpload', function() {
    var fileInput = $(this);
    if(fileInput.hasClass('directUpload_inited')) return;

    fileInput.addClass('directUpload_inited');
    var form         = $(fileInput.closest('form'));
    var submitButton = form.find('input[type="submit"]');
    var progressBar  = $("<div class='bar'></div>");
    var barContainer = $("<div class='fileupload_progress'></div>").append(progressBar);
    var errorContainer = $('<div class="fileupload_error"></div>');
    var sanitize_filename = function(name) {
      var s = name.toLowerCase().replace(/(à|â)/g, 'a').replace(/(é|è|ê|ë)/g, 'e').replace(/(ô|ö)/g, 'o').replace(/(ï|î)/g, 'i').replace(/(ü|û|ù)/g, 'u').replace(/[^a-z0-9\.]+/g,'-').replace(/(^-|-$)/g,'');
      var ext = s.substring(s.lastIndexOf(".") + 1, s.length);
      var filename = s.replace('.' + ext,'');
      return filename.substr(0, 23) + '.' + ext;
    }
    var autosubmit = fileInput.data('autosubmit');
    var fileType = fileInput.data('fileType');
    var maxFileSize = fileInput.data('maxFileSize');
    fileInput.after(barContainer);
    fileInput.before(errorContainer);

    const fileUploadOptions = {
      fileInput:       fileInput,
      url:             fileInput.data('url'),
      type:            'POST',
      autoUpload:       true,
      formData:         fileInput.data('form-data'),
      paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      process: function(e, data) {
        errorContainer.text("");
        var name = data.files[0].name;
        fileInput.data('form-data').key = fileInput.data('form-data').key.replace("${filename}", sanitize_filename(name));
        data.submit();
      },

      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },

      start: function (e) {
        submitButton.prop('disabled', true);
        uploadInProgressCount++;

        progressBar.
          css('background', 'green').
          css('display', 'block').
          css('width', '0%').
          css('height', '5px');
      },

      done: function(e, data) {
        progressBar.text("Terminé !");
        // extract key and generate URL from response
        var key   = $(data.jqXHR.responseXML).find("Key").text();
        var url   = '//' + fileInput.data('host') + '/' + key;
        // create hidden field
        var input = $("<input />", { type:'hidden', name: fileInput.attr('name'), value: key })
        form.append(input);
        fileInput.remove();
        uploadInProgressCount--;
        if(uploadInProgressCount <= 0) submitButton.prop('disabled', false);
        if(autosubmit) {
          progressBar.css('background', 'transparent').text("Soumission...");
          railsFire(form[0], "submit");
          input.remove();
        }
      },

      processfail: function(e, data) {
        uploadInProgressCount--;
        if(uploadInProgressCount <= 0) submitButton.prop('disabled', false);
        if(data.files[0].error) {
          var error = data.files[0].error;
          if(error) {
            errorContainer.text(error);
          }
        }
        progressBar.
          css("background", "red").
          text("Echec");
      }
    };
    if(typeof(fileType) !== "undefined") {
      fileUploadOptions['acceptFileTypes'] = new RegExp("(\.|\/)(" + fileType + ")$", "i");
    }
    if(typeof(maxFileSize) !== "undefined") {
      fileUploadOptions['maxFileSize'] = maxFileSize;
    }

    fileInput.fileupload(fileUploadOptions);
    form.find('input[type="submit"]').prop('disabled', false);
  });
})
