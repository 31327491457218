document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.excerpt-read-more').forEach(button => {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      const targetId = this.getAttribute('data-trigger'); // Utilise data-trigger pour obtenir l'ID
      const productExcerpt = document.querySelector(`.product-excerpt[data-target="${targetId}"]`);
      const readMore = document.querySelector(`.excerpt-read-more[data-exerpt="${targetId}"]`);
      const readLess = document.querySelector(`.excerpt-read-less[data-exerpt="${targetId}"]`);
      // Cache le pseudo-élément ::before de cet extrait
      productExcerpt.classList.add('expanded');
      readMore.classList.add('expanded');
      readLess?.classList?.remove("d-none")
    });
  });
  document.querySelectorAll('.excerpt-read-less').forEach(button => {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      const targetId = this.getAttribute('data-trigger'); // Utilise data-trigger pour obtenir l'ID
      const productExcerpt = document.querySelector(`.product-excerpt[data-target="${targetId}"]`);
      const readMore = document.querySelector(`.excerpt-read-more[data-exerpt="${targetId}"]`);
      const readLess = document.querySelector(`.excerpt-read-less[data-exerpt="${targetId}"]`);
      // Cache le pseudo-élément ::before de cet extrait
      productExcerpt.classList.remove('expanded');
      readMore.classList.remove('expanded');
      readMore.classList.remove('d-none');
      readLess.classList.add("d-none")
      productExcerpt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
    });
  });
});