document.addEventListener('DOMContentLoaded', function () {
  const infoBanner = document.querySelector('#info_banner');

  if (infoBanner) {
    if (document.cookie.match(/hideInfoBanner=1/)) {
      document.querySelectorAll('#info_banner').forEach(function (element) {
        element.classList.add('d-none');
      });
    } else {
      document.querySelectorAll('#info_banner.d-none').forEach(function (element) {
        element.classList.remove('d-none');
      });
    }
    document.getElementById('close_info_banner').addEventListener('click', function (event) {
      document.querySelectorAll('#info_banner').forEach(function (element) {
        element.style.transition = 'opacity 400ms';
        element.style.opacity = '0';
        element.addEventListener('transitionend', function () {
          element.classList.add('d-none');
        });
      });

      var cookieName = 'hideInfoBanner';
      var cookieValue = '1';
      var expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);

      document.cookie = cookieName + '=' + encodeURIComponent(cookieValue) + '; expires=' + expirationDate.toUTCString();
    });
  };
});
