$(function() {
  $("[data-refresh-url]").each(function() {
    var url = $(this).data("refresh-url");
    var interval = parseInt($(this).data("interval-seconds"), 10) * 1000;
    var intervalObject = setInterval(function() {
      $.get(url).done(function(response) { if(response === "OK") { top.location.reload(); } })
                .fail(function(response) { $(elem).html("<p>Cette page est momentanément inaccessible. Merci de réitérer votre demande ultérieurement.</p>"); });
    }, interval);
  });
  $("[data-poll-url]").each(function() {
    var elem = this;
    var url = $(this).data("poll-url");
    var interval = parseInt($(elem).data("interval-seconds"), 10) * 1000;
    var stopPollingSelector = $(elem).data("stop-polling");
    var intervalObject = setInterval(function() {
      if($(stopPollingSelector).length === 0) {
        $.rails.ajax({url: url, type: "GET"});
      }
    }, interval);
  });

  $('[data-href]').click(function() {
    $.get($(this).data('href'), '', '', 'script');
  });

  $('body').on('click', '[data-toggle-and-show]', function(e) {
    var el = $(this);
    var targets = el.data("toggle-and-show").split(",").map(function(elem) { return $(elem); });
    targets.map(function(elem) {
      elem.toggleClass("hidden");
    });
    $(el.data("hide-and-show")).hide();
    $(el.data("show-and-show")).show();
    return true;
  });

  $('body').on('click', '.tooltip-alert a.close-button', function() {
    $(this).closest(".tooltip-container").addClass("fade");
  });

  $('body').on('click', 'a[data-remote]', function() {
    $(this).find('.loading-spinner').show();
  });

  $('body').on('click', '[data-click-url]', function() {
    top.location.href=$(this).data("click-url");
  });

  $('body').on('click', '[data-click-url] a[data-remote]', function(e) {
    $.rails.handleRemote(e.target);
    e.stopPropagation();
  });

  $('body').on('click', '[data-toggle]', function(e) {
    e.preventDefault();
    $(this).data('toggle').split(' ').forEach(function(elem) {
      $(elem).toggleClass('hidden');
    });
  });

  $('body').on('click', '[data-toggle-and-click]', function(e) {
    $(this).data('toggle-and-click').split(' ').forEach(function(elem) {
      $(elem).toggleClass('hidden');
    });
  });

  $('body').on('click change', '[data-master-of]', function(e) {
    var el = $(this);
    var inputs = $(el.data('master-of'));
    inputs.val(el.val());
  });
});

// exporting globally rails fire submit
const railsFire = (el, eventName) => {
  return $.rails.fire(el, eventName);
}

global.railsFire = railsFire
