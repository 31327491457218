$(document).ready(function () {
  $('.tab-pane iframe').each(function () {
    if ($(this).closest('p.video-container').length === 0 && $(this).closest('iframe.pdf-container').length == 0) {
      $(this).removeAttr('width').attr('frameborder', 0).removeAttr('height').addClass('video');
      $(this).wrap('<p class="video-container"></p>');
    }
  });
  // if(typeof(google) === "undefined") return;
  // var $map = $('#map');

  // if( $map.length ) {
  //   var options = { minZoom: 5 };
  //   var map = new google.maps.Map(document.getElementById('map'), options);
  //   var points = $map.data('points');
  //   var bounds  = new google.maps.LatLngBounds();

  //   points.map(function(latlng) {
  //     var marker = new google.maps.Marker({
  //       position: new google.maps.LatLng(latlng.latitude, latlng.longitude),
  //       icon: latlng.icon,
  //       map: map
  //     });
  //     var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
  //     bounds.extend(loc);
  //   });
  //   var zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function(event) {
  //     google.maps.event.removeListener(zoomChangeBoundsListener);
  //     map.setZoom( Math.min( 12, map.getZoom() ) );
  //   });
  //   map.fitBounds(bounds);
  // }
  $('.menu_project').change(function () {
    var selected_option = $(this).find('option:selected').data('href');
    $('.project_tabs a[aria-controls="' + selected_option + '"]').tab('show');
  });

  $('.project_tabs .menu_left a').on('click', function () {
    var sticky_menu_height = 172;
    var selected_option = "#" + $(this).attr("aria-controls");
    setTimeout(function () {
      $('html, body').animate({
        scrollTop: $(selected_option).offset().top - sticky_menu_height
      })
    }, 100);
  });

  if (location.hash && location.hash.match(/^#go/)) {
    // TODO syntax error : `[aria-controls='${}']` (missing "']")
    const el = document.querySelector(`[aria-controls='${location.hash.split("#go")[1]}`)
    $(el).click();
  }

  $('#send-call-back-request').on('submit', function () {
    $('#callback_modal').modal('toggle');
  });
  $('body.page-projects').on('submit', '#new_call_me_back', function () {
    $('#new_call_me_back input.btn').addClass('disabled').val('...');
  });

  $('#project-comments').on('submit', '.new_project_comment', function (e) {
    $('.new_project_comment input[type="submit"]').val("...").attr({
      "disabled": "disabled"
    });
  });
  update_equity_simulator = function () {
    var amount = parseInt($('.equity-simulator input').val(), 10);
    var ratio = parseFloat($('.equity-simulator .simulator-result').data('ratio'));
    $('.equity-simulator .simulator-result').html(parseInt(amount * ratio * 100, 10)/100); // with cents
  }
  if ($('.equity-simulator').length > 0) {
    update_equity_simulator();
    $('.equity-simulator input').on("change", update_equity_simulator);
  }
  set_error_message = function () {
    document.querySelectorAll('.simulator-input-wrapper').forEach(inputWrapper => {
      const input = inputWrapper.querySelector('.simulator-input')
      if (!input.checkValidity()) {
        var min = Math.floor(parseFloat(input.value) / parseFloat(input.step)) * parseFloat(input.step)
        var max = Math.ceil(parseFloat(input.value) / parseFloat(input.step)) * parseFloat(input.step)
        inputWrapper.querySelector('.simulator-input-error').classList.remove("d-none")
        inputWrapper.querySelector('.simulator-input-error p').innerHTML = input.dataset.validityMessage.replace('placeholder_lower_value', min).replace('placeholder_higher_value', max)
      } else {
        inputWrapper.querySelector('.simulator-input-error').classList.add("d-none")
      }
    })
  }
  if ($(".simulator-input").length > 0 ) {
    $('.simulator-input').on("change", set_error_message);
  }
});
