import { I18n } from "i18n-js"
const i18n = new I18n()

  i18n.store({fr: {"countdown":{"collect_started":"La collecte a démarré !","days_short":"j.","hours_short":"h.","minutes_short":"min.","seconds_short":"sec."},"recaptcha":{"error_message":"Veuillez désactiver tout bloqueur de publicités ou autres extensions qui pourraient interférer avec le processus de connexion et recharger la page."},"documents":{"buttons":{"edit":"Modifier"},"dropzone":{"message":"Glissez / déposez votre document ici ou cliquez pour le sélectionner sur votre ordinateur"}},"subscriptions":{"buttons":{"invest":"Investir","invest_anyway":"Investir quand même","waiting":"...un Instant"},"errors":{"max_exceeded":"Le montant que vous souhaitez investir est supérieur au maximum autorisé sur le projet"},"tokens":{"partner_error_message":"Vous n'êtes pas éligible à investir en tant que partenaire veuillez contacter le service Relations Investisseurs"}},"investor_app":{"bank_accounts":{"validation":{"bic_invalid":"BIC invalide","iban_invalid":"IBAN invalide"}}}}});

  i18n.store({en: {"countdown":{"collect_started":"The collection has started!","days_short":"j.","hours_short":"h.","minutes_short":"min.","seconds_short":"sec."},"recaptcha":{"error_message":"Please disable any add blockers or other extensions that may be interfering with the sign in process and reload the page."},"documents":{"buttons":{"edit":"Edit"},"dropzone":{"message":"Drag and drop your document here or click to select it on your computer"}},"subscriptions":{"buttons":{"invest":"Invest","invest_anyway":"Investing anyway","waiting":"...an Instant"},"errors":{"max_exceeded":"The amount you wish to invest is greater than the maximum authorized for the project"},"tokens":{"partner_error_message":"If you are not eligible to invest as a partner, please contact the Investor Relations department"}},"investor_app":{"bank_accounts":{"validation":{"bic_invalid":"Invalid BIC","iban_invalid":"Invalid IBAN"}}}}});

  i18n.store({es: {"countdown":{"collect_started":"¡La recaudación ha comenzado!","days_short":"d.","hours_short":"h.","minutes_short":"min.","seconds_short":"seg."},"recaptcha":{"error_message":"Por favor, desactiva cualquier bloqueador de anuncios u otras extensiones que puedan estar interfiriendo con el proceso de inicio de sesión y recarga la página."},"documents":{"buttons":{"edit":"Modificar"},"dropzone":{"message":"Arrastra / suelta tu documento aquí o haz clic para seleccionarlo en tu ordenador"}},"subscriptions":{"buttons":{"invest":"Invertir","invest_anyway":"Invertir de todos modos","waiting":"...un momento"},"errors":{"max_exceeded":"El importe que deseas invertir es superior al máximo autorizado en el proyecto"},"tokens":{"partner_error_message":"No cumples con los requisitos para invertir como socio, contacta con el servicio al cliente."}},"investor_app":{"bank_accounts":{"validation":{"bic_invalid":"BIC inválido","iban_invalid":"IBAN inválido"}}}}});

  i18n.store({it: {"countdown":{"collect_started":"La campagna di raccolta è iniziata!","days_short":"j.","hours_short":"h.","minutes_short":"min.","seconds_short":"secco."},"recaptcha":{"error_message":"Si prega di disabilitare qualsiasi blocco pubblicità o altre estensioni che potrebbero interferire con il processo di accesso e ricaricare la pagina."},"documents":{"buttons":{"edit":"Modificare"},"dropzone":{"message":"Trascinate qui il tuo documento o fate clic per selezionarlo sul tuo computer"}},"subscriptions":{"buttons":{"invest":"Investire","invest_anyway":"Investire comunque","waiting":"... un istante"},"errors":{"max_exceeded":"L'importo che si desidera investire è superiore al limite massimo autorizzato per il progetto"},"tokens":{"partner_error_message":"Se non siete idonei a investire come partner, contattate l'Ufficio relazioni con gli investitori"}},"investor_app":{"bank_accounts":{"validation":{"bic_invalid":"BIC non valido","iban_invalid":"IBAN non valido"}}}}});

  i18n.store({nl: {"countdown":{"collect_started":"De inzamelingsactie is begonnen!","days_short":"d","hours_short":"u","minutes_short":"min","seconds_short":"sec"},"recaptcha":{"error_message":"Schakel eventuele adblockers of andere extensies uit die het aanmeldingsproces kunnen verstoren en herlaad de pagina."},"documents":{"buttons":{"edit":"Aanpassen"},"dropzone":{"message":"Sleep je document hierheen of klik om het op je computer te selecteren"}},"subscriptions":{"buttons":{"invest":"Investeer","invest_anyway":"Toch investeren","waiting":"...een ogenblik"},"errors":{"max_exceeded":"Het bedrag dat je wilt investeren is hoger dan het toegestane maximum voor het project"},"tokens":{"partner_error_message":"Als je niet in aanmerking komt om als partner te investeren, neem dan contact op met de afdeling Investeerdersrelaties"}}}});


i18n.defaultLocale = "en";
i18n.fallbacks = true // also see Rails config.i18n.fallbacks
i18n.locale = document.documentElement.lang

export default i18n
