import { Popover } from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
  const subscriptionHistoryHolder = document.querySelector('[data-subscription-history]');
  if(subscriptionHistoryHolder !== null) {

    const addBadge = (projectId, badgeHolder, initial) => {
//        console.log(`adding ${initial} to project ${projectId}`);
        let badge = document.createElement('span');
        badge.classList.add(`investor-${indexFor(initial)}`);
        badge.innerText = initial;
        badgeHolder.appendChild(badge);

    }

    const popoverContent = (arr) => {
        let ul = document.createElement('ul');
        arr.forEach((element) => {
            let li = document.createElement('li');
            li.innerText = element;
            ul.appendChild(li);
        });
        return ul
    }
    const subscriptionHistory = JSON.parse(subscriptionHistoryHolder.dataset.subscriptionHistory);
//    console.log(subscriptionHistory)
    const projects = document.querySelectorAll('[data-project-id]');
    const investorsList = subscriptionHistory['summary']['initials'];

    const indexFor = (initial) => {
        return investorsList.indexOf(initial);
    }

    projects.forEach((projectElement) => {
      let projectId = projectElement.dataset.projectId;
//      console.log(projectId);
      let projectData = subscriptionHistory['summary'][projectId];
      if(projectData !== undefined) {
        let badgeHolder = document.querySelector(`[data-project-id="${projectId}"] .already-invested`);
        projectData['initials'].forEach((initial) => {
            addBadge(projectId, badgeHolder, initial);
        });
        badgeHolder.dataset.content = "bla yooo";
        new Popover(badgeHolder, {
          container: 'body',
          trigger: 'hover',
          html: true,
          content: popoverContent(projectData['to_words'])
        });
      }
    });
  }
});