window.animate_bar = function() {
  var percentage = parseInt($('.progress-bar').attr('aria-overtarget'), 10);
  var no_fiesta = document.getElementsByClassName('no-fiesta').length;
  var initial_width = parseInt($('.progress-bar').attr('aria-valuenow'), 10);
  $('.progress-bar').css({width: 0});
  setTimeout(function() {
    $('.progress-bar').css({width: initial_width + "%"});
    window.increase_target();
    if(percentage >= 100 && no_fiesta === 0) { setTimeout(function() { window.confetti(); }, 500); }
  }, 1000);
}

window.increase_target = function() {
  var target_elem = $('span.target');
  var target_value = parseInt(target_elem.data('value'), 10);
  var current_value = 0;
  var step = target_value / 50;
  itv = setInterval(function() {
    current_value += step;
    if(current_value >= target_value) { clearInterval(itv); current_value = target_value; }
    target_elem.html(number_format(current_value) + " &euro;");
  }, 10);

  number_format = function(num) {
    if(typeof(Intl) === "undefined") {
      return num;
    } else {
      return new Intl.NumberFormat("fr-FR").format(num);
    }
  }
}
window.confetti = function() {
  canvas = document.getElementById("confetti");
  context = canvas.getContext("2d");
  width = canvas.width / 4;
  height = canvas.height / 4;
  hiddenParticles = 0;

  // création d'un tableau
  particle = [];
  particleCount = 150;
  gravity = 0.2;
  colors = [
    '#3fc0ea', '#fdca00', '#adbacc', '#98c000', '#f6a82d'
  ];

  for( var i = 0; i < particleCount; i++){
    velX = randomRange(-1.5,1.5);
    velY = randomRange(-4,-1) - velX;
    accelY = 1 - (randomRange(2,5)/100);
    accelX = 1 - (randomRange(2,5)/100);
    particle.push({
      x : width * 2 + 10,
      y : height * 3,
      boxW : 1,
      boxH : 3,
      size : randomRange(1,4),
      velX : velX,
      velY : velY,
      accelY: accelY,
      accelX: accelX,
      angle :convertToRadians(randomRange(0,360)),
      color:colors[Math.floor(Math.random() * colors.length)],
      anglespin:randomRange(-0.1, 0.1),
      oscill_speed: randomRange(1,5),
      alpha: 1
    });
  }

  function draw(p) {
    context.save();
    context.translate(p.x, p.y);
    context.rotate(p.angle);
    context.fillStyle=p.color;
    context.globalAlpha = p.alpha;
    context.beginPath();

    context.fillRect(p.boxW/2*-1, p.boxH/2*-1, p.boxW, p.boxH);
    context.fill();
    context.closePath();
    context.restore();
    p.angle += p.anglespin;
    p.velY *= p.accelY;
    p.velY += (0.005 * randomRange(1,4));
    if(p.accelY > 0 && p.y > height * 2) { p.alpha -= 0.005; p.alpha = Math.max(0, p.alpha); }

    p.x += (p.velX * randomRange(1,4));
    if(p.velY > 0) {
      p.x += Math.cos(((p.y / (3*p.velY)) * p.oscill_speed) % 90) * p.velY;
    }
    p.y += p.velY;
    p.velX*= p.accelX;
    if(p.alpha === 0) {
      var index = particle.indexOf(p);
      if (index > -1) {
        particle.splice(index, 1);
      }
    }
  }

  function drawScreen(){

    for( var i = 0; i < particle.length; i++){
      draw(particle[i]);
    }
    if(particle.length <= 10) $('#confetti').remove();
  }

  function update(){
    context.clearRect(0, 0, canvas.width, canvas.height);
    drawScreen();
    requestAnimationFrame(update);
  }

  update();

  function randomRange(min, max){
    return min + Math.random() * (max - min );
  }

  function randomInt(min, max){
    return Math.floor(min + Math.random()* (max - min + 1));
  }
  function convertToRadians(degree) {
    return degree*(Math.PI/180);
  }
}

window.addEventListener("load", function load(event){
  window.removeEventListener("load", load, false); //remove listener, no longer needed
  if($('.progress-bar').length) window.animate_bar();
},false);
