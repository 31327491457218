document.addEventListener('DOMContentLoaded', function () {
  const likedProjectsContainer = document.querySelector('#like-list');

  if (likedProjectsContainer) {
    const likedProjects = likedProjectsContainer.dataset.alreadyLikedProjects;
    if (likedProjects) {
      const projectIds = likedProjects.split(',');

      projectIds.forEach(function (projectId) {
        const likeButton = document.querySelector('[data-bs-target="#like-modal-' + projectId + '"] .ComponentsButtons--like');
        if (likeButton) {
          likeButton.classList.add('liked');
        }
      });
    };
  }
});
