document.addEventListener("DOMContentLoaded", () => {
  const SellCertificate = document.querySelector(".page-investor_app-sell_certificates-show")

  if (SellCertificate) {
    let priceField = document.querySelector("[data-min-reasonable-unitprice-eur]")
    let quantityField = document.querySelector(priceField.dataset.quantityField)
    const display_warning_if_unreasonable = (elem) => {
      let min = parseFloat(elem.dataset.minReasonableUnitpriceEur);
      let max = parseFloat(elem.dataset.maxReasonableUnitpriceEur);
      let qtty = parseInt(quantityField.value);
      let alertElement = document.querySelector(elem.dataset.alert);
      let current_value = parseFloat(elem.value)
      if(current_value > max*qtty || current_value < min*qtty) {
        alertElement.classList.remove("d-none");
      } else {
        alertElement.classList.add("d-none");
      }
    }

    const display_calculate_tri = (price, quantity) => {
      const positiveTriField = document.querySelector("#positive-calc-tri-display");
      const positiveTri = document.querySelector("#positive-calc-tri");
      const negativeTriField = document.querySelector("#negative-calc-tri-display");
      const negativeTri = document.querySelector("#negative-calc-tri");
      const submitButton = document.querySelector("[data-test-id='submit_form_btn']");

      if (price && price > 0 && quantity && quantity > 0) {
        $.ajax({
          type: 'GET',
          url: $("#positive-calc-tri-container").data("calculate-tri-url"),
          data: {
            quantity: quantity,
            price: price,
          },
          dataType: "json"
        }).then((data) => {
          if (data > 0) {
            submitButton.classList.remove("disabled");
            negativeTriField.classList.add("d-none");
            positiveTriField.classList.remove("d-none");
            positiveTri.innerHTML = "<p><strong>" + parseFloat(data).toFixed(2) + " %</strong></p>";
          } else {
            submitButton.classList.add("disabled");
            positiveTriField.classList.add("d-none");
            negativeTriField.classList.remove("d-none");
            negativeTri.innerHTML = "<p><strong>" + parseFloat(data).toFixed(2) + " %</strong></p>";
          }
        });
      } else {
        positiveTriField.classList.add("d-none");
        negativeTriField.classList.add("d-none");
      }
    };

    priceField.addEventListener("keyup", () => {

      let price = parseFloat(priceField.value);
      let quantity = parseInt(quantityField.value);

      display_warning_if_unreasonable(priceField)
      quantityField.addEventListener("keyup", () => { display_warning_if_unreasonable(quantityField) })
      display_calculate_tri(price, quantity);
    })
  }
})
