import I18n from "../../../i18n/setup.js.erb";

document.addEventListener('DOMContentLoaded', function () {
  const signUpForm = document.getElementById('userLoginForm');
  if (signUpForm) {
    const submitBtn = document.getElementById('submitBtn');
    submitBtn.addEventListener('click', () => {
      submitBtn.classList.add('disabled');
      const initialText = submitBtn.innerHTML;
      submitBtn.innerHTML = submitBtn.dataset.loadingText 
      grecaptcha.execute().then((token) => {
        if (typeof token === 'string') {
          submitBtn.innerHTML = initialText;
          submitBtn.classList.remove('disabled');
          notie.alert({"type": "warning", "text": I18n.t("recaptcha.error_message"), "time": 10});
        }
      })
    })
  }

  var submitNewUserForm = function () {
    document.getElementById("userLoginForm").submit();
  };

window.submitNewUserForm = submitNewUserForm;
});
