import "jquery.countdown/dist/jquery.countdown.js";
import I18n from "../../../i18n/setup.js.erb";

$(function() {
  function flashCountDown() {
    var date = $(this).data('start-time');
    var $this = $(this);
    $this.countdown(date, function(event) {
      var days_template = '<span><span class="count">%D</span><span class="label"> ' + I18n.t('countdown.days_short') + '</span></span>';
      var hrs_template = '<span><span class="count">%H</span><span class="label"> ' + I18n.t('countdown.hours_short') + '</span></span>';
      var mins_template = '<span><span class="count">%M</span><span class="label"> ' + I18n.t('countdown.minutes_short') + '</span></span>';
      var secs_template = '<span><span class="count">%S</span><span class="label"> ' + I18n.t('countdown.seconds_short') + '</span></span>';
      if(event.offset.totalDays > 0) {
        $this.html(event.strftime(days_template + hrs_template + mins_template + secs_template));
      } else {
        var totalHours = event.offset.totalDays * 24 + event.offset.hours;
        hrs_template = '<span><span class="count">' + totalHours + '</span><span class="label"> ' + I18n.t('countdown.hours_short') + '</span></span>';
        $this.html(event.strftime(hrs_template + mins_template + secs_template));
      }

      if(event.offset.totalSeconds <= 0) {
        $this.html(I18n.t('countdown.collect_started'));
      }
    }).on('finish.countdown', function(event) {
      $this.html(I18n.t('countdown.collect_started'));
      top.location.reload();
    });
  }
  $('.countdown-widget-countdown').each(flashCountDown);
});
