document.addEventListener('DOMContentLoaded', function() {
  const triggers = document.querySelectorAll('[data-arkefip-toggle="overlay"]');
  let backdrop = document.querySelector('.overlay-backdrop');
  let overlayDismissButton;

  if (!backdrop) {
    backdrop = document.createElement('div');
    backdrop.className = 'overlay-backdrop';
    document.body.appendChild(backdrop);
  }

  if (!overlayDismissButton) {
    overlayDismissButton = document.createElement('button');
    overlayDismissButton.className = 'overlay-dismiss';
    overlayDismissButton.innerHTML = '&times;';
    document.body.appendChild(overlayDismissButton);
    overlayDismissButton.style.display = 'none';
  }

  triggers.forEach(trigger => {
    trigger.addEventListener('click', function(e) {
      e.preventDefault();

      const targetId = this.getAttribute('data-arkefip-target');
      const overlay = document.getElementById(targetId);

      document.querySelectorAll('.overlay.show').forEach(openOverlay => {
        if (openOverlay !== overlay) {
          closeOverlay(openOverlay, backdrop, overlayDismissButton);
        }
      });

      openOverlay(overlay, backdrop, overlayDismissButton);
    });
  });

  function openOverlay(overlay, backdrop, overlayDismissButton) {
    overlay.style.display = 'block';
    backdrop.style.display = 'block';
    document.body.style.overflow = 'hidden';

    // waiting for style is edited
    requestAnimationFrame(() => {
        overlay.classList.add('show');
        backdrop.classList.add('show');

        setTimeout(() => {
            overlayDismissButton.style.display = 'block';
            overlayDismissButton.classList.add('show');
        }, 400); // delaying button appearance 
    });

    overlayDismissButton.onclick = () => closeOverlay(overlay, backdrop, overlayDismissButton);
    backdrop.onclick = () => closeOverlay(overlay, backdrop, overlayDismissButton);
  }

  function closeOverlay(overlay, backdrop, overlayDismissButton) {
    overlay.classList.remove('show');
    backdrop.classList.remove('show');
    overlayDismissButton.classList.remove('show');

    let transitionEnded = (event) => {
        if (event.target === overlay) {
            document.body.style.overflow = '';
            overlay.style.display = 'none';
            backdrop.style.display = 'none';
            overlayDismissButton.style.display = 'none';
            overlay.removeEventListener('transitionend', transitionEnded);
        }
    };

    overlay.addEventListener('transitionend', transitionEnded);
  }
});
