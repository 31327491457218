$(function() {
  var form = $('#projects-contact-form form');
  var button = $('.projects-contact-form-focus');

  if (button.length > 0 && form.length > 0) {
    button.click(function(){
      form.find('input[type=text], input[type=email], select').first().focus();
    });
  }
});
