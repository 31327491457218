document.addEventListener("DOMContentLoaded", () => {
  const inputWrappers = document.querySelectorAll(".otp-attempt");
  if (inputWrappers) {
    inputWrappers.forEach((inputWrapper) => {
      const input = inputWrapper.querySelector("input");
      input.addEventListener("input", () => {
        input.style.setProperty('--_otp-digit', input.selectionStart)
      })
    })
  }
});
