const customDataToggle = () => {
  const customToggleElements = document.querySelectorAll("[data-custom-toggle]")
  if (customToggleElements) {
    customToggleElements.forEach(element => {
      element.addEventListener("click", () => {
        const classesToToggle = element.dataset.customToggle.split(" ")
        const classToToggle = typeof element.dataset.customToggleClass !== "undefined" ? element.dataset.customToggleClass : "d-none"
        classesToToggle.forEach(c => {
          document.querySelectorAll(c).forEach(e => e.classList.toggle(classToToggle))
        })
      })
    })
  }
}

document.addEventListener("DOMContentLoaded", () => {
  customDataToggle();
})